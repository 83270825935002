import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { TokenService } from '../core/services/token.service';
import { ResendAllRatesChannelsRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ResendAllRatesChannelsService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private dateFormatter: DateFormatterService,
  ) {}

  resendAllRatesChannels(data: ResendAllRatesChannelsRequest) {
    return this.http.post(`channelmanager/resend_all_rates`, data);
  }
}
