import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ResendAllRatesChannelsService } from '../../services/resend-all-rates-channles.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ResendAllRatesChannelsStoreEffects {
  constructor(
    private dataService: ResendAllRatesChannelsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
  ) {}

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendRequest),
      switchMap(({ data }) =>
        this.dataService.resendAllRatesChannels(data).pipe(
          map(() => {
            this._notifications.push({
              title: this._translate.instant('done'),
              content: this._translate.instant(
                'notifications.generic_success',
                {
                  param: this._translate.instant('rates_and_availabilities'),
                },
              ),
              type: 'success',
            });
            return fromActions.resendSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.resendFailure(error));
          }),
        ),
      ),
    ),
  );
}
