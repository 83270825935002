import { ChannelIdentifyPropertyResponse } from '@app/models/objects/channel-identify-property';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export default createActionGroup({
  source: 'Channel Identity Property',
  events: {
    'Load Request': props<{
      channelId: number;
      accomodation_xml_id: number;
    }>(),
    'Load Success': props<{
      property: ChannelIdentifyPropertyResponse;
    }>(),
    'Load Failure': props<{ error: any }>(),
    'Reset State': emptyProps(),
  },
});
