import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { channelIdentifyPropertyReducer } from './reducer';
import { ChannelIdentifyPropertyStoreEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'channelIdentifyProperty',
      channelIdentifyPropertyReducer,
    ),
    EffectsModule.forFeature([ChannelIdentifyPropertyStoreEffects]),
  ],
})
export class ChannelIdentifyPropertyStoreModule {}
