import { createAction, props } from '@ngrx/store';

import { ResendAllRatesChannelsRequest } from '../../models';

export const resendRequest = createAction(
  '[Resend All Rates Channels] Resend Request',
  props<{ data: ResendAllRatesChannelsRequest }>(),
);

export const resendSuccess = createAction(
  '[Resend All Rates Channels] Resend Success',
);

export const resendFailure = createAction(
  '[Resend All Rates Channels] Resend Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Resend All Rates Channels] Reset State',
);
