import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import fromActions from './actions';
import { ChannelIdentifyPropertyService } from '@app/services';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ChannelIdentifyPropertyResponse } from '@app/models/objects/channel-identify-property';

@Injectable()
export class ChannelIdentifyPropertyStoreEffects {
  constructor(
    private dataService: ChannelIdentifyPropertyService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ channelId, accomodation_xml_id }) =>
        this.dataService.load(channelId, accomodation_xml_id).pipe(
          map((response: IResponseSuccess<ChannelIdentifyPropertyResponse>) =>
            fromActions.loadSuccess({
              property: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
