import { createAction, props } from '@ngrx/store';

import { CheckReservationChannel } from '../../models';
import { Hooks } from '../../helpers';

export const checkReservarionChannelRequest = createAction(
  '[Check Reservation Channel] Check Request',
  props<{ data: CheckReservationChannel; hooks: Hooks }>(),
);

export const checkReservarionChannelSuccess = createAction(
  '[Check Reservation Channel] Check Success',
);

export const checkReservarionChannelFailure = createAction(
  '[Check Reservation Channel] Check Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Check Reservation Channel] Reset State',
);
