import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getEntities = (state: State) => state.identifiedProperty;

export const selectState = createFeatureSelector<State>(
  'channelIdentifyProperty',
);

export const selectIdentifiedProperty = createSelector(
  selectState,
  getEntities,
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
